var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-breadcrumbs", {
    staticStyle: {
      "padding-left": "5px !important",
      "flex-direction": "row",
      display: "flex",
      padding: "0px !important",
      margin: "0px !important",
    },
    attrs: { items: _vm.items },
    scopedSlots: _vm._u([
      {
        key: "divider",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticClass: "material-icons-outlined",
                staticStyle: {
                  display: "flex",
                  width: "16px",
                  height: "16px",
                  "justify-content": "center",
                  "align-items": "center",
                  color: "#8d8d99 !important",
                  "pointer-events": "none !important",
                },
                attrs: { id: "arrowId" },
              },
              [_vm._v(" keyboard_arrow_right ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            item.title === "Home"
              ? _c(
                  "v-breadcrumbs-item",
                  {
                    staticClass: "breadcrumb_home",
                    attrs: { icon: "", href: "/home", disabled: item.disabled },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "shadow-none",
                        staticStyle: { width: "32px", height: "32px" },
                        attrs: {
                          fab: "",
                          "x-large": "",
                          id: "homeButton",
                          ripple: false,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "material-icons-outlined shadow-none textColor--text",
                          },
                          [_vm._v(" home ")]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "v-breadcrumbs-item",
                  { attrs: { href: item.href, disabled: item.disabled } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "breadSelected",
                        class: _vm.activeLink(item.active),
                        attrs: { id: item.title },
                      },
                      [_vm._v(" " + _vm._s(item.title))]
                    ),
                  ]
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }