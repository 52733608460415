<template>
      <v-breadcrumbs
        :items="items"
        style="
          padding-left: 5px !important;
          flex-direction: row;
          display: flex;
          padding: 0px !important;
          margin: 0px !important;
        "
      >
        <template v-slot:divider>
          <span
            class="material-icons-outlined"
            id="arrowId"
            style="
              display: flex;
              width: 16px;
              height: 16px;
              
              justify-content: center;
              align-items: center;
              color: #8d8d99 !important;
              pointer-events: none !important;
            "
          >
            keyboard_arrow_right
          </span>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item icon v-if="item.title === 'Home'" :href="'/home'" :disabled="item.disabled" class="breadcrumb_home">
            <v-btn fab x-large style="width: 32px; height: 32px" id="homeButton" class="shadow-none" :ripple="false">
              <span class="material-icons-outlined shadow-none textColor--text"> home </span>
            </v-btn>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" v-else>
            <span class="breadSelected" :id="item.title" :class="activeLink(item.active)"> {{ item.title }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
<script>
    export default {
        props: {
            items: {
                default: [{
                    title: 'Home',
                    disabled: false,
                    href: 'home',
                },
                {
                    title: 'Página 1',
                    disabled: true,
                },
                {
                  title: 'Página 2',
                  disabled: false,
                  active: true
                }
              ]
            }
        },

        methods: {
          activeLink (active) {
            return active ? 'bread-crumb-active' : '';
          }
        }
    }
</script>
<style lang="scss" scoped>
@import './src/design/variables.scss';

.material-icons-outlined {
  font-size: $font-size-sm;
  font-weight: 400 !important;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  &:active {
    color: var(--v-primary-base);
    font-weight: 400 !important;
    background-color:$brand-color-highlight-medium;
  }
}

::v-deep {
  .breadcrumb_home {
    margin-left: 0;
    margin-right: 8px;
    padding-left: 0;
    .v-breadcrumbs__item {
      margin: 0;
      padding: 0;
    }
}

  .breadSelected {
    font-size: $font-size-xxs !important;
    font-weight: $font-weight-regular !important;
    line-height: $line-height-md;
    &:hover {
      color: var(--v-primaryMedium-base)
    }
  }
  .v-breadcrumbs__item {
    padding: 0 16px;
    &.v-breadcrumbs__item--disabled {
        color: $neutral-color-high-light;
    }
  }
  
  .v-breadcrumbs__divider {
    margin: 0;
    width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
    font-weight: $font-weight-regular;
    align-items: start;
    color: $neutral-color-high-light;
  }

  #homeButton {
    padding: 4px !important;
    border-radius: 28px !important;
    background-color: transparent;
    box-shadow: none !important;
    .material-icons-outlined{
      color: var(--v-primary-base) !important;
    }
    
    &:hover {
      border-radius: 28px !important;
      background-color: $brand-color-highlight-light;
      box-shadow: none !important;
      outline: none !important;
      .material-icons-outlined {
        background-color: transparent !important;
        border: 0px solid transparent !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    &:active {
      background-color: $brand-color-highlight-medium;
    }
  }
}

.bread-crumb-active {
  font-weight: $font-weight-bold !important;
}

</style>